.top{
    padding-top: 100px;
}

@media (max-width: 979px) {
    .top {
      padding-top: 100px;
    }
  }

label{
    font-size: 18px;
}

input, textarea{
    background-color: #DDDDDD !important;
    font-size: 18px !important;
    height: 60px;
    border: none !important;
}